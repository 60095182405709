import "core-js/modules/es.array.push.js";
// import routes from '../router/routes'
import { Dialog, showToast } from 'vant';
var Enumerable = require('linq');
export default {
  name: 'index',
  data() {
    return {
      images: [{
        id: 1,
        url: '/assets/1.jpg'
      }, {
        id: 2,
        url: '/assets/2.jpg'
      }, {
        id: 3,
        url: '/assets/3.jpg'
      }, {
        id: 4,
        url: '/assets/4.png'
      }],
      signatures: 0,
      showAll: false,
      showPopover: false,
      showAction: false,
      actions: [{
        text: '选项一'
      }, {
        text: '选项二'
      }, {
        text: '选项三'
      }],
      activeNames: ['1'],
      studentInfo: {
        idCard: '000000000000000000'
      },
      myplans: [{
        id: 1,
        name: '11111'
      }, {
        id: 2,
        name: '222222'
      }, {
        id: 3,
        name: '3333333'
      }],
      sex: ['未知', '男', '女'],
      scaleInfos: [],
      scaleInfos1: [],
      scaleInfos2: [],
      scaleInfos3: [],
      loading: true
    };
  },
  methods: {
    onSelect(action) {
      console.log(action);
      var that = this;
      this.planTemp = action;
      this.showAction = false;
      // that.$toast(action.name);
    },

    getmytask() {
      var that = this;
      var idCard = localStorage.getItem('idCard');
      that.$http.get('/api/old/getMyPlans', {
        idCard: idCard
      }, '正在检索量表，请稍后').then(result => {
        console.log(result);
        if (result.success) {
          console.log(result.data);
          that.$data.scaleInfos = result.data;
          that.$data.scaleInfos1 = Enumerable.default.from(this.$data.scaleInfos).where('o=>o.state==0').toArray();
          that.$data.scaleInfos2 = Enumerable.default.from(this.$data.scaleInfos).where('o=>o.state==1').toArray();
          that.$data.scaleInfos3 = Enumerable.default.from(this.$data.scaleInfos).where('o=>o.state==2').toArray();
        } else {
          showToast({
            message: result.msg,
            duration: 0
          });
          setTimeout(() => {
            this.$router.push({
              path: './info'
            });
          }, 1500);

          //this.showQYWXCode();
        }
      });
    },

    getmySign() {
      var that = this;
      that.$http.get('/api/ycf/getSign', null, '正在检索量表，请稍后').then(result => {
        console.log(result);
        if (result.success) {
          console.log(result.data);
          that.signatures = result.data;
        } else {
          if (result.msg == '请先签名') {
            that.$router.push('/sign');
          } else {
            // showToast(result.msg);
          }
          //this.showQYWXCode();
        }
      });
    },

    goReport(scale) {
      if (scale.state == 2) {
        this.$router.push({
          path: './report1',
          query: {
            id: scale.id
          }
        });
      } else {
        showToast('没有可用报告！');
      }
    },
    goTest(scale) {
      if (this.$data.signatures > 0) {
        this.$router.push({
          path: './testDo',
          query: {
            id: scale.id
          }
        });
      } else {
        this.$router.push({
          path: './sign',
          query: {
            id: scale.id
          }
        });
      }
    }
  },
  created() {},
  mounted() {
    var userstr = localStorage.getItem('user');
    var user = JSON.parse(userstr);
    this.studentInfo = user.studentInfo;
    this.getmytask();
    this.getmySign();
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }
};